import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import brochure from "../files/brochure_v7.pdf"

import Layout from "../components/layout"
import homepage from "../images/homepage.jpg"

import "./index.css"

const IndexPage = () => {
  return (
    <Layout
      imageUrl={homepage}
      pageTitle="Old Court Wedding Company"
      subTitle="Strangford Co Down"
    >
      <div className="section intro-block">
        <div className="headline">
          A unique location for your whole wedding day
        </div>
        <div className="paragraph">
          Nestled peacefully on the shores of Strangford Lough, beside the
          charming village of Strangford, lies Old Court. This private estate is
          home to a stunning 17th-century church, lovingly restored stables, a
          yurt-inspired banquet hall, a private harbour, boathouse, and several
          acres of natural gardens stretching down to the shore.
        </div>
        <button className="button info-block__text__button">
          <a href={brochure} target="_blank" rel="noreferrer" download>
            Download brochure
          </a>
        </button>
      </div>

      <div className="section info-block">
        <div className="info-block__text">
          <div className="info-block__text__item">
            You could host your special day at Old Court, exclusively reserved
            for you and your guests. This extraordinary venue, with a maximum
            capacity of 120, guarantees to create memories that will stay with
            you for a lifetime. What's more? We want you to relax and enjoy your
            wedding day to the fullest, which is why we offer you the freedom to
            set up and decorate the venue the day before, just as you like it.
            This means that you can be rest assured that everything is perfectly
            arranged, allowing you to focus on enjoying your wedding day.
          </div>
          <button className="button info-block__text__button">
            <Link to={"/pricing"}>Pricing & further info</Link>
          </button>
        </div>
        <div className="info-block__picture">
          <StaticImage
            src="../images/homepage-pricing-link.jpg"
            alt="Barn image"
            placeholder="blurred"
            layout="fixed"
            width={400}
          />
        </div>
      </div>

      <div className="section gallery-block">
        <div className="gallery-block__images">
          <StaticImage
            src="../images/gallery/chapel6-min.jpg"
            alt="Barn image"
            placeholder="blurred"
            layout="fixed"
            width={400}
          />
          <StaticImage
            src="../images/gallery/barn1-min.jpg"
            alt="Barn image"
            placeholder="blurred"
            layout="fixed"
            width={400}
          />
          <StaticImage
            src="../images/gallery/boathouse1-min.jpg"
            alt="Barn image"
            placeholder="blurred"
            layout="fixed"
            width={400}
          />
        </div>
        <div className="gallery-block__button">
          <button className="button">
            <Link to={"/gallery"}>View Gallery</Link>
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
